<template>
  
</template>
<script>
export default {
  name: 'redirect',
  beforeCreate() {
    console.log(this.$route)
    this.$router.replace({
      path: this.$route.query.path,
    })
    
  },
}
</script>
